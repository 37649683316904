import { cloneDeep, set } from 'lodash';
import { defineMessages, useIntl } from 'react-intl';

import { ArgCombo, useClassNames } from 'src/components/basic';
import {
    useAccessRuleStateContext,
    useOntologyContext,
} from '../../../../common/providers/policy-rules-provider';
import { EffectFilter } from '../../../../../models/policy';
import { DropdownTooltipField } from '../../../../common/dropdown-tooltip-field/dropdown-tooltip-field';
import { ReadOnlyFilterInput } from 'src/settings/universes/common/rules/read-only-filter-input/read-only-filter-input';
import { getPredicate, splitMetapropertyPredicate } from './utils';
import { getPropertyCompatibilityCode } from 'src/settings/universes/utils';
import { OntologyMetaProperty } from 'src/settings/universes/ontology/types';

import './rule-effect.less';


const messages = defineMessages({
    dropdownsDescription: {
        id: 'settings.metaproperty-dropdowns.dropdownsDescription',
        defaultMessage: 'where the metaproperty',
    },
    select: {
        id: 'settings.metaproperty-dropdowns.select',
        defaultMessage: 'Select',
    },
    isEqualTo: {
        id: 'settings.metaproperty-dropdowns.isEqualTo',
        defaultMessage: 'is equal to',
    },
    isDifferentThan: {
        id: 'settings.metaproperty-dropdowns.isDifferentThan',
        defaultMessage: 'is different than',
    },
    isGreaterThan: {
        id: 'settings.metaproperty-dropdowns.isGreaterThan',
        defaultMessage: 'is greater than',
    },
    isSmallerThan: {
        id: 'settings.metaproperty-dropdowns.isSmallerThan',
        defaultMessage: 'is smaller than',
    },
    isGreaterOrEqualTo: {
        id: 'settings.metaproperty-dropdowns.isGreaterOrEqualTo',
        defaultMessage: 'is greater or equal to',
    },
    isSmallerOrEqualTo: {
        id: 'settings.metaproperty-dropdowns.isSmallerOrEqualTo',
        defaultMessage: 'is smaller or equal to',
    },
    universeContext: {
        id: 'settings.metaproperty-dropdowns.universeContext',
        defaultMessage: 'Property:',
    },
    userContext: {
        id: 'settings.metaproperty-dropdowns.userContext',
        defaultMessage: 'User:',
    },
});

interface MetapropertyDropdownsProps {
    effectValue: EffectFilter;
    index: number;
}

interface FixedValue {
    name?: string;
    displayName?: string;
}

export function MetapropertyDropdowns(props: MetapropertyDropdownsProps) {
    const { effectValue, index } = props;

    const intl = useIntl();
    const classNames = useClassNames('settings-rule-effect');
    const { editable, setRule } = useAccessRuleStateContext();
    const { ontologySchema, userProfileFields } = useOntologyContext();
    const metapropertiesList = ontologySchema.metaProperties || [];

    const objectPropertyOperatorToUser = (operator: string | undefined) => {
        if (operator === '=') return intl.formatMessage(messages.isEqualTo);
        if (operator === '!=') return intl.formatMessage(messages.isDifferentThan);
        if (operator === '>') return intl.formatMessage(messages.isGreaterThan);
        if (operator === '<') return intl.formatMessage(messages.isSmallerThan);
        if (operator === '>=') return intl.formatMessage(messages.isGreaterOrEqualTo);
        if (operator === '<=') return intl.formatMessage(messages.isSmallerOrEqualTo);

        return undefined;
    };
    const objectPropertyOperatorToSchema = (operator: string | undefined) => {
        if (operator === intl.formatMessage(messages.isEqualTo)) return '=';
        if (operator === intl.formatMessage(messages.isDifferentThan)) return '!=';
        if (operator === intl.formatMessage(messages.isGreaterThan)) return '>';
        if (operator === intl.formatMessage(messages.isSmallerThan)) return '<';
        if (operator === intl.formatMessage(messages.isGreaterOrEqualTo)) return '>=';
        if (operator === intl.formatMessage(messages.isSmallerOrEqualTo)) return '<=';

        return '';
    };

    const meta = effectValue.targets?.[0]?.meta;

    const [metapropertyName, metaropertyPredicate] = meta
        ? Object.entries(meta)[0] || [undefined, undefined]
        : [undefined, undefined];

    const metapropertySelected = metapropertiesList.find(
        (metaproperty) => metaproperty.name === metapropertyName,
    );

    const universePrefix = intl.formatMessage(messages.universeContext);
    const userPrefix = intl.formatMessage(messages.userContext);

    const validUserFields = userProfileFields.filter(
        (field) =>
            field.typeCompatibilityCode === getPropertyCompatibilityCode(metapropertySelected) ||
            !field.typeCompatibilityCode,
    );

    const { userOrMeta, operator, value } = splitMetapropertyPredicate(metaropertyPredicate);

    const thirdDropdownOptions: FixedValue[] = [
        ...(metapropertySelected?.constraint?.fixedValues?.map((p) => {
            return { name: p.value, displayName: `${universePrefix}${p.value}` };
        }) || []),
        ...validUserFields.map((profileField) => {
            return { name: profileField.id, displayName: `${userPrefix}${profileField.displayName}` };
        }),
    ];

    const fixedValueSelected = thirdDropdownOptions.find(
        (fixedValue) => fixedValue.name === value,
    );

    return (
        <>
            <div className={classNames('&-metaproperty')}>
                {intl.formatMessage(messages.dropdownsDescription)}
            </div>
            {editable ? (
                <>
                    <ArgCombo<OntologyMetaProperty>
                        className={classNames('&-metaproperty-selection')}
                        placeholder={intl.formatMessage(messages.select)}
                        items={metapropertiesList}
                        getItemKey={(metaProperty) => metaProperty.name}
                        getItemLabel={(metaProperty) => metaProperty.displayName}
                        value={metapropertySelected}
                        popoverClassName='arg-input-popover-no-max-width'
                        cardinality='one'
                        size='small'
                        onChange={(newMetaProperty: OntologyMetaProperty) => {
                            setRule((currentRule) => {
                                if (newMetaProperty.name === metapropertySelected?.name) {
                                    return currentRule;
                                }

                                return set(
                                    cloneDeep(currentRule),
                                    `Effects[${index}].property.targets[0].meta`,
                                    { [newMetaProperty.name]: '' },
                                );
                            });
                        }}
                        renderInput={() => (
                            <DropdownTooltipField
                                className={classNames('&-metaproperty-selection')}
                                value={metapropertySelected?.displayName}
                            />
                        )}
                    />
                    {metapropertySelected && (
                        <>
                            <ArgCombo
                                className={classNames('&-property-operator')}
                                placeholder={intl.formatMessage(messages.select)}
                                items={[
                                    intl.formatMessage(messages.isEqualTo),
                                    intl.formatMessage(messages.isDifferentThan),
                                    intl.formatMessage(messages.isGreaterThan),
                                    intl.formatMessage(messages.isSmallerThan),
                                    intl.formatMessage(messages.isGreaterOrEqualTo),
                                    intl.formatMessage(messages.isSmallerOrEqualTo),
                                ]}
                                value={objectPropertyOperatorToUser(operator)}
                                popoverClassName='arg-input-popover-no-max-width'
                                cardinality='one'
                                size='small'
                                onChange={(operatorTextSelected) => {
                                    setRule((currentRule) => {
                                        const newOperator =
                                            objectPropertyOperatorToSchema(operatorTextSelected);
                                        if (newOperator === operator) {
                                            return currentRule;
                                        }

                                        return set(
                                            cloneDeep(currentRule),
                                            `Effects[${index}].property.targets[0].meta`,
                                            {
                                                [metapropertySelected.name]: getPredicate(
                                                    userOrMeta,
                                                    newOperator,
                                                    value,
                                                ),
                                            },
                                        );
                                    });
                                }}
                                renderInput={() => (
                                    <DropdownTooltipField
                                        className={classNames('&-property-operator')}
                                        value={objectPropertyOperatorToUser(operator)}
                                    />
                                )}
                            />
                            <ArgCombo<FixedValue>
                                className={classNames('&-metaproperty-fixed-value-selection')}
                                placeholder={intl.formatMessage(messages.select)}
                                items={thirdDropdownOptions}
                                value={{ name: value }}
                                getItemKey={(fixedValue) => (fixedValue.name || null)}
                                getItemLabel={(fixedValue) => fixedValue.displayName}
                                popoverClassName='arg-input-popover-no-max-width'
                                cardinality='one'
                                size='small'
                                renderInput={() => (
                                    <DropdownTooltipField
                                        className={classNames(
                                            '&-metaproperty-fixed-value-selection',
                                        )}
                                        value={fixedValueSelected?.displayName}
                                    />
                                )}
                                onChange={(newValue) => {
                                    setRule((currentRule) => {
                                        if (newValue.name === value) {
                                            return currentRule;
                                        }

                                        const fixedValues = metapropertySelected.constraint?.fixedValues;
                                        const isInFixedValues = fixedValues?.some((fv) => fv.value === newValue.name);

                                        if (isInFixedValues) {
                                            return set(
                                                cloneDeep(currentRule),
                                                `Effects[${index}].property.targets[0].meta`,
                                                {
                                                    [metapropertySelected.name]: getPredicate(
                                                        'meta',
                                                        operator,
                                                        newValue.name,
                                                    ),
                                                },
                                            );
                                        } else {
                                            return set(
                                                cloneDeep(currentRule),
                                                `Effects[${index}].property.targets[0].meta`,
                                                {
                                                    [metapropertySelected.name]: getPredicate(
                                                        'user',
                                                        operator,
                                                        newValue.name,
                                                    ),
                                                },
                                            );
                                        }
                                    });
                                }}
                            />
                        </>
                    )}
                </>
            ) : (
                <>
                    <ReadOnlyFilterInput
                        className={classNames('&-property-operator')}
                        value={metapropertySelected?.displayName}
                    />
                    {metapropertySelected && (
                        <>
                            <ReadOnlyFilterInput
                                className={classNames('&-property-value')}
                                value={objectPropertyOperatorToUser(operator)}
                            />
                            <ReadOnlyFilterInput
                                className={classNames('&-property-value')}
                                value={fixedValueSelected?.displayName}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}
